<template>
    <div class="datapage-div">
        <el-card class="el-card-main">
            <el-row class="row-input-div" :gutter="10">
                <el-col :span="3">
                    <el-input placeholder="请输入学生姓名" v-model="queryInfo.studentname" clearable @clear="payData"> 
                    </el-input>
                </el-col>
                <el-col :span="3">
                    <el-input placeholder="请输入经办人" v-model="queryInfo.optionuser" clearable @clear="payData"> 
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-date-picker
                        v-model="queryInfo.createstarttime"
                        type="date"
                        placeholder="缴费起始日期"
                        format="yyyy-MM-dd"
                        :picker-options="pickerOptionsStart">
                    </el-date-picker>
                </el-col>
                <el-col :span="4">
                    <el-date-picker
                        style="display:flex"
                        v-model="queryInfo.createendtime"
                        type="date"
                        placeholder="缴费结束日期"
                        format="yyyy-MM-dd"
                        :picker-options="pickerOptionsEnd">
                    </el-date-picker>
                </el-col>
                <el-col :span="4">
                    <el-button style="display:flex" type="primary" @click="payData">查询</el-button>
                </el-col>
            </el-row>
            <el-row class="row-data-div" :gutter="10">
                <el-col :span="20">
                    <el-card>
                        <el-table :data="payList" v-loading="loading" @filter-change="filterChange" height="66vh">
                            <div slot="empty">
                                <el-empty description="暂无数据！" />
                            </div>
                            <el-table-column label="#" type="index"></el-table-column>
                            <el-table-column label="姓名" prop="studentName" :width="flexColumnWidth('姓名', 'studentName')"></el-table-column>
                            <!-- filters属性绑定的是自定义查询的数据，格式必须是text和value组合成的对象 -->
                            <el-table-column label="会员" prop="membership" filter-placement="bottom-end" column-key="membership" :filters="identityList">
                                <template slot-scope="scope">
                                    <el-tag type="danger" v-if="scope.row.membership === 0 " disable-transitions>
                                        非会员
                                    </el-tag>
                                    <el-tag type="success" v-if="scope.row.membership === 1 " disable-transitions>
                                        会员
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="应缴金额(￥)" prop="payable"></el-table-column>
                            <el-table-column label="实缴金额(￥)" prop="paid"></el-table-column>
                            <el-table-column label="优惠金额(￥)" prop="discount"></el-table-column>
                            <el-table-column label="缴费方式" prop="wayName" filter-placement="bottom-end" column-key="wayName" :filters="payWayList"></el-table-column>
                            <el-table-column label="活动" prop="sealTypeName"></el-table-column>
                            <el-table-column label="类型" prop="typeName"></el-table-column>
                            <el-table-column label="发票" prop="name"></el-table-column>
                            <el-table-column label="经办人" prop="operationName"></el-table-column>
                            <el-table-column label="缴费日期" prop="createTime" :width="flexColumnWidth('缴费日期', 'createTime')"></el-table-column>
                            <el-table-column label="状态" prop="stateName" filter-placement="bottom-end" column-key="stateName" :filters="stateList"></el-table-column>
                        </el-table>
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card>
                        <div ref="paymentstatisticschart" class="chart_div">
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.start"
                :page-sizes="spreadCount"
                :page-size="queryInfo.count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import { GetPayQuery, GetPaysStatistisc } from '@/assets/js/api/payapi';
import { GetALlPayWayList } from '@/assets/js/api/paywayapi';
import { GetMaxLength } from '@/assets/js/common.js';
import * as echarts from 'echarts';
import { SpreadCount } from '@/assets/js/systemvariable.js';
export default {
    mounted(){
        this.getPayWay()
        this.payData()
    },
    data() {
        return {
            spreadCount:SpreadCount,
            queryInfo:{
                membership: -1,
                studentname: '',
                optionuser: '',
                payways: [],
                status: [],
                start: 0,
                count: SpreadCount[0],
                createstarttime:'',
                createendtime:''
            },
            payList:[],
            total: 0,
            identityList:[{ text: '会员', value: 1 }, { text: '非会员', value: 0 }],
            // 缴费状态（0：等待缴费；1：缴费成功；2：退费中；3：退费；4：完成）
            stateList:[
                { text: '待缴费', value: 0 }, 
                { text: '已缴费', value: 1 },
                { text: '退费中', value: 2 },
                { text: '退费', value: 3 },
                { text: '合同完成', value: 4 }
            ],
            loading:true,
            pickerOptionsStart: {
                disabledDate: time => {
                    let endDateVal = this.queryInfo.createendtime;
                    if (endDateVal) {
                        return time.getTime() > new Date(endDateVal).getTime();
                    }
                }
            },
            pickerOptionsEnd: {
                disabledDate: time => {
                    let beginDateVal = this.queryInfo.createstarttime;
                    if (beginDateVal) {
                        return (
                            time.getTime() <
                            new Date(beginDateVal).getTime()
                        );
                    }
                },
            },
            pwLoading: true,
            payWayList:[],
            statistics:[],
        }
    },
    watch:{
        pwLoading: function(newval){
            if(newval == false && this.loading == false){
                this.loading = false
            }
        },
    },
    methods:{
        flexColumnWidth(label, prop){
            const arr = this.payList.map(x => x[prop])
            arr.push(label) 
            return (GetMaxLength(arr) + 25) + 'px'
        },
        filterChange(filters){
            var filterkey = Object.keys(filters)[0]
            this.queryInfo.start = 1
            if(filterkey === "membership"){
                if(filters.membership.length === 1){
                    this.queryInfo.membership = filters.membership[0]
                }
                else{
                    this.queryInfo.membership = -1 //查询全部
                }
            }
            else if(filterkey === "wayName"){
                this.queryInfo.payways = filters.wayName
            }
            else if(filterkey === "stateName"){
                this.queryInfo.status = filters.stateName
            }
            this.payData()
        },
        async getPayWay(){
            this.pwLoading = true
            var ret = await GetALlPayWayList()
            this.pwLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                for(var i = 0; i < ret.data.length; i ++){
                    var obj = {text:ret.data[i].name,value:ret.data[i].id}
                    this.payWayList.push(obj)
                }
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        async payData(){
            this.loading = true
            var ret = await GetPayQuery(this.queryInfo)
            this.payStatistics()
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.payList = ret.data.pays
                this.total = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        handleSizeChange(count){
            this.queryInfo.count = count
            this.payData()
        },
        handleCurrentChange(start){
            this.queryInfo.start = start
            this.payData()
        },
        async payStatistics(){
            var ret = await GetPaysStatistisc(this.queryInfo)
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.statistics = ret.data
            }
            else
            {
                this.$message.error(ret.message);
            }
            this.$nextTick(function () {
                this.getChart();
            }, 1000);
        },
        getChart(){
            var option = {
                title: {
                    text: '缴费占比',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: '缴费状态',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                            formatter: function(params) {
                                var total = 0; // 定义一个变量存储总数
                                var data = option.series[0].data; // 获取数据源
                                for (var i = 0; i < data.length; i++) {
                                    total += data[i].value; // 计算总数
                                }
                                var percent = ((params.value / total) * 100).toFixed(2); // 计算百分比
                                return percent + '%'; // 格式化字符串
                            },
                        },
                        emphasis: {
                            label: {
                            show: true,
                            fontSize: 30,
                            fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                    }
                ]
            };
            // { text: '待缴费', value: 0 }, 
            //     { text: '已缴费', value: 1 },
            //     { text: '退费中', value: 2 },
            //     { text: '退费', value: 3 },
            //     { text: '合同完成', value: 4 }
            option.series[0].data = this.statistics
            if (this.$refs.paymentstatisticschart == null) {
                return
            }
            echarts.dispose(this.$refs.paymentstatisticschart)
            var myChart  = echarts.init(this.$refs.paymentstatisticschart)
            option && myChart.setOption(option);
        }
    }
}
</script>